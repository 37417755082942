import { PageHeader } from "antd";
import React from "react";

// displays a page header

export default function Header() {
  return (
    <a href="https://www.label-dao.com/" target="_blank" rel="noopener noreferrer">
      <PageHeader
        title={<h3 style= {{ backgroundColor: "#0eff5a", color: "black" }}> LabelDAO</h3>}
        subTitle=""
        style={{ cursor: "pointer" }}
      />
    </a>
  );
}
